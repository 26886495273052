<template>
    <v-container fluid class="ma-0 pa-8">
        <v-row class="ma-0 pa-0">
            <v-col cols="auto">
                <span>User Feedback</span>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
            <v-spacer></v-spacer>
            <v-col sm="12" md="10">
                <v-data-table
                    :headers="headers"
                    :items="user_registration"
                    :item-class= "getRowClasses"
                    style="width: 100%;"
                    height="500px"
                    :hide-default-footer="user_registration.length < 15"
                    :items-per-page="15"
                >
                </v-data-table>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </v-container>
</template>

<script>
// import {getFirestore, collection, getDocs} from 'firebase/firestore'
// import firebase_app from '../../plugins/firebase.js'
// import {getAuth} from 'firebase/auth';
export default {
    name: 'FeedbackBasePage',

    components: {
    },

    data: () => ({
        user_registration: [],
        "headers": [
            { "text": "Date", "value": "date", "sortable": true},
            { "text": "Rating", "value": "rating", "sortable": true},
            { "text": "Comment", "value": "comment", "sortable": false},
            { "text": "Can Contact", "value": "can_contact", "sortable": true}
        ]
    }),
    mounted(){
        // this.get_users();
    },
    methods: {
        // get_users: async function(){
        //     const auth = getAuth();
        //     if(auth.currentUser !== null){
        //         this.user_registration = [];
        //         let db = getFirestore(firebase_app);
        //         let users_info = collection(db, "user_feedback");
        //         const querySnapshot = await getDocs(users_info);
        //         querySnapshot.forEach((doc) => {
        //             this.user_registration.push(doc.data());
        //         });
        //     }
        // }
    }

}
</script>
