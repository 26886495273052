<template>
  <user-manage/>
</template>

<script>
  import UserManage from '../components/UserManagement/UserManager.vue'

  export default {
    name: 'BasePage',

    components: {
      UserManage
    },
  }
</script>
