import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
// import { getAuth } from 'firebase/auth';
// import firebase_app from './plugins/firebase'

Vue.config.productionTip = false

// Import the Auth0 configuration
import { domain, clientId, authorizationParams } from '../auth_config.json';

// Import the plugin
import { Auth0Plugin } from './auth';

Vue.prototype.$environment = {
  val: false,
  flip_val: function(){
      this.val = !this.val;
  },
  get_val: function(){
      return this.val;
  }};

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  authorizationParams,
  onRedirectCallback: appState => {
    history.replaceState({}, document.title, window.location.pathname);
    console.log("on redirect callback")
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : "/home"
    );
  }
});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');

// let app = '';

// let auth = getAuth();

// auth.onAuthStateChanged(() => {
//   if (!app) {
//       /* eslint-disable no-new */
//       app = new Vue({
//         firebase_app,
//         vuetify,
//         router,
//         render: h => h(App)
//       }).$mount('#app')
//   }
// });