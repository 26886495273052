import Vue from 'vue'
import Router from 'vue-router'
// import { getAuth } from 'firebase/auth';

import LoginPage from '../views/LoginView.vue'
import BasePage from '../views/HomeView.vue';
import SignUps from '../views/SignUpsView.vue';
import FeedBack from '../views/FeedbackView.vue';

import { authGuard } from '../auth/authGuard';

Vue.use(Router)

const router = new Router({
    routes : [
        {
          path: '*',
          redirect: '/login'
        },
        {
          path: '/',
          redirect: '/login'
        },
        {
          path: '/home',
          name: 'Home',
          component: BasePage,
          meta: {
              requiresAuth: true,
              title: 'Docma',
              hideNavbar: false
          },
          beforeEnter: authGuard
        },
        {
          path: '/signups',
          name: 'SignUps',
          component: SignUps,
          meta: {
              requiresAuth: true,
              title: 'Docma'
          },
          beforeEnter: authGuard
        },
        {
          path: '/feedback',
          name: 'Feedback',
          component: FeedBack,
          meta: {
              requiresAuth: true,
              title: 'Docma'
          },
          beforeEnter: authGuard
        },
        {
          path: '/login',
          name: 'Login',
        //   redirect: '/ServerMaintenance',
          component: LoginPage,
          meta: {
              title: 'Docma',
              hideNavbar: true
          }
        }
    ]
});

// router.beforeEach((to,from,next) => {
//     const auth = getAuth();
//     const currentUser = auth.currentUser;
//     const requiresAuth = to.matched.some(record=>record.meta.requiresAuth);

//     document.title = to.meta.title;

//     if(requiresAuth && currentUser === null){
//         next('Login');
//     }
//     else{
//         next();
//     }
// });

export default router;
