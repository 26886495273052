import axios from 'axios'

const prod_api_endpoint = 'https://enterprise.api.docmasweetspot.ca'
const stage_api_endpoint = 'https://staging.enterprise.api.docmasweetspot.ca'
// const api_endpoint = 'http://127.0.0.1:5001'

function get_endpoint(env){
    if(env){
        return prod_api_endpoint;
    }
    else{
        return stage_api_endpoint;
    }
}

function get_all_users(token, env) {
    let api_url = get_endpoint(env)+'/user_management/get_all_users'
    return(axios_post_call(api_url,{},token))
}

function get_all_orgs(token, env) {
    let api_url = get_endpoint(env)+'/org_management/get_all_orgs'
    return(axios_post_call(api_url,{},token))
}

function get_org_members(token, org_id, env) {
    let api_url = get_endpoint(env)+'/org_management/get_org_members'
    let data = {"org_id": org_id };
    return(axios_post_call(api_url, data, token))
}

function add_org_members(token, org_id, user_id, env) {
    let api_url = get_endpoint(env)+'/org_management/add_org_member'
    let data = {"org_id": org_id, "user_id": user_id };
    return(axios_post_call(api_url, data, token))
}

function create_new_user(token, user_payload, env) {
    let api_url = get_endpoint(env)+'/user_management/create_new_user'
    return(axios_post_call(api_url, user_payload, token))
}

function get_all_projects(token, env){
    let api_url = get_endpoint(env)+'/project_management/get_available_access'
    return(axios_post_call(api_url, {}, token))
}

function assign_access(token, uid, env, assign=[], remove=[]){
    let api_url = get_endpoint(env)+'/project_management/assign_user_access'
    let payload = {
        'user_id': uid,
        'assign': assign,
        'remove': remove
    }
    return(axios_post_call(api_url,payload, token))
}

/*
Base Caller functions
*/
function axios_post_call(api_url,data,token){
    return new Promise(function(resolve,reject){
        axios.post(api_url, data, {headers: {Authorization: `Bearer ${token}`}}).then((res)=>{
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        }).catch((e)=>{
            console.log(e)
            reject(api_url+e);
        })
    })
}

export default {get_all_users, get_all_orgs, get_org_members, add_org_members, create_new_user, get_all_projects, assign_access}