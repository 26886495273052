<template>
  <v-app>
    <!-- 
      User Nav Drawer 
    -->
    <v-navigation-drawer
      v-if="$auth.isAuthenticated && !$route.meta.hideNavbar"
      v-model="user_drawer"
      temporary
      color="primary"
      dark
      right
      app
    >
      <template v-slot:prepend>
        <v-list-item one-line>
          <v-list-item-avatar style="background-color: #FFFFFF50;">
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="docma-text-navigation-user">{{$auth.user.name}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider class="ma-0 pa-0 mx-6"></v-divider>
      <v-col cols="12">
        <v-switch :value="staging" label="Production" @change="flip_environment"></v-switch>
      </v-col>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn class="docma-text-navigation-logout" block @click="logout">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <!--
      APP Bar
    -->
    <v-app-bar
      v-if="$auth.isAuthenticated && !$route.meta.hideNavbar"
      app
      color="primary"
      dark
      height="45px"
      extension-height="48px"
      class="ma-0 pa-0"
    >
      <v-row class="ma-0 pa-0 px-3" max-height="100%">
        <v-col cols="4" class="ma-0 pa-0" style="max-height:45px">
            <v-img :src="require(`${main_logo_inverterd}`)" contain max-width="150px" max-height="30px"></v-img>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn class="ma-0 pa-0 docma-text-navigation-bar-btn" text plain dark @click="user_drawer=true">
          <span class="docma-text-navigation-bar-text">Welcome {{user_first_name}}</span>
          <v-icon>mdi-account-outline</v-icon>
        </v-btn>
      </v-row>


      <template v-slot:extension>
        <v-row class="ma-0 pa-0" style="height: 100%;">
          <v-col cols="10" class="ma-0 pa-0 px-3" style="max-height:48px">
            <v-tabs align-tabs="start" dark>
              <v-tab class="docma-tool-navigation-bar" to="/home">User Management</v-tab>
              <v-tab class="docma-tool-navigation-bar" to="/feedback">Feedback</v-tab>
              <v-tab class="docma-tool-navigation-bar" to="/signups"></v-tab>
            </v-tabs>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </template>
    </v-app-bar>
    <!-- 
      APP Content
     -->
    <v-main style="height: calc(100vh-98px); max-height: calc(100vh-98px); overflow-y: hidden; overflow-x: hidden;" v-if="page_available">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// import {getFirestore, doc, getDoc} from 'firebase/firestore'
// import firebase_app from './plugins/firebase.js'
// import {getAuth} from 'firebase/auth';
export default {
  name: 'App',
  mounted(){
    if(this.$auth.isAuthenticated) {
      this.user_first_name = this.$auth.user.given_name; 
      this.user_full_name = this.$auth.user.name;
      this.staging = this.$environment.get_val();
    }
  },
  updated (){
    if(this.$auth.isAuthenticated) {
      this.user_first_name = this.$auth.user.given_name; 
      this.user_full_name = this.$auth.user.name;
      this.staging = this.$environment.get_val();
    }
  },
  data: () => ({
    main_logo: './assets/docma-logo-dark.svg',
    main_logo_inverterd: './assets/docma-logo-reversed.svg',

    logged_in: false,
    user_drawer: false,

    user_first_name: '',
    user_full_name: '',

    staging: false,

    page_available: true
  }),
  methods: {
    // is_logged_in: async function(){
    //   const auth = getAuth();
    //   let user = auth.currentUser;
    //   if(user === null){
    //     this.logged_in = false;
    //   }
    //   else{
    //     this.logged_in = true
    //     let db = getFirestore(firebase_app);
    //     let users_info = doc(db, "docma_user_settings",user.uid);
    //     let user_data = await getDoc(users_info);
    //     if(user_data.exists()){
    //       let data = user_data.data();
    //       this.user_first_name = data['first_name'];
    //       this.user_full_name = data['first_name'] + " " + data['last_name'];
    //     }
    //     else{
    //       this.logout();
    //     }
    //   }
    // },
    flip_environment: async function(){
      this.page_available = false;
      this.$environment.flip_val();
      setTimeout(() => this.page_available = true, 1000)
    },
    logout: function() {
      this.$auth.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
  }
};
</script>
<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Poppins:200,400,600,800');
  @import url('https://fonts.googleapis.com/css?family=Amiko:400,600,700');
  .docma-tool-navigation-bar{
      font-family: 'Poppins' !important;
      font-weight: 600 !important;
      font-size: 0.9em !important;
      color: white;
  }
  .docma-text-navigation-bar-text{
    font-family: 'Amiko' !important;
    font-size: 0.7em;
  }
  .docma-text-navigation-bar-btn{
    height: 30px !important;
  }
  /deep/ .v-toolbar__content {
      padding: 0px !important;
  }
  /deep/ .v-toolbar__extension {
      padding: 0px !important;
  }
</style>