<template>
    <DigitalRFBasePage />
</template>

<script>
import DigitalRFBasePage from '../components/DigitalRFTracking/DigitalRFBasePage.vue'

export default {
    name: 'DigitalRF',

    components: {
        DigitalRFBasePage,
    },
}
</script>
