<template>
    <FeedbackBasePage />
</template>

<script>
import FeedbackBasePage from '../components/Feedback/FeedbackBasePage.vue'

export default {
    name: 'FeedBack',

    components: {
        FeedbackBasePage,
    },
}
</script>
