<template>
    <v-card class="ma-12 pa-6" light style="width: 80vw; height: 95vh; overflow-y: scroll">
        <v-row>
            <v-col cols="auto">
                <v-icon @click="close">mdi-close</v-icon>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <!-- <v-tabs v-model="action_tab" align-tabs="start">
            <v-tab class="docma-tool-navigation-bar" key="create_user">Create New User</v-tab>
            <v-tab class="docma-tool-navigation-bar" key="assign_user_to_org">Assign User to Organization</v-tab>
        </v-tabs>
        <v-tabs-items v-model="action_tab">
            <v-tab-item> -->
                <v-card-title class="user-add-card-title ma-0 pa-3">
                    <span v-if="confirm">Confirm User Details</span>
                    <span v-else>Create New User</span>
                </v-card-title>
                <v-form class="ma-0 pa-3">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                            <span class="user-add-section-title">User Details</span>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field :rules="rules.name" label="First name" required class="user-add-input" v-model="first_name" :disabled="confirm"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field :rules="rules.name" label="Last name" required class="user-add-input" v-model="last_name" :disabled="confirm"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field label="Title" class="user-add-input" v-model="title" hint="Title" :disabled="confirm"></v-text-field>
                        </v-col>
                        <v-col cols="10">
                            <v-select class="user-add-input" v-model="organization" :items="organization_options" @change="update_organization_logo" :disabled="confirm"></v-select>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-img :src="logo" contain></v-img>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                            <span class="user-add-section-title">Login Credentials</span>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field :rules="rules.email" label="Email" class="user-add-input" v-model="email" :disabled="confirm"></v-text-field>
                        </v-col>
                        <v-col lg="10" md="8" sm="8">
                            <v-text-field :rules="rules.name" label="Password" class="user-add-input" v-model="password" :disabled="confirm"></v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" v-if="!confirm">
                            <v-btn icon @click="create_random_password">
                                <v-icon>
                                    mdi-reload
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto" >
                            <v-btn icon @click="copy_text_to_clipboard">
                                <v-icon>
                                    mdi-content-copy
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0" justify="space-around">
                        <v-col cols="auto" v-if="!confirm">
                            <v-btn class="user-add-input" @click="clear_content">
                                Clear
                            </v-btn>
                        </v-col>
                        <v-col cols="auto" v-if="!confirm">
                            <v-btn class="user-add-input" @click="confirm_user_detail" :disabled="!form_filled">
                                Continue
                            </v-btn>
                        </v-col>
                        <v-col cols="auto" v-if="confirm">
                            <v-btn class="user-add-input" @click="update_user_detail">
                                Previous
                            </v-btn>
                        </v-col>
                        <v-col cols="auto" v-if="confirm" @click="add_user">
                            <v-btn class="user-add-input">
                                Add User
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            <!-- </v-tab-item> -->
            <!-- <v-tab-item>
                <v-form class="ma-0 pa-3">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12">
                            <span class="user-add-section-title">User</span>
                        </v-col>
                        <v-col cols="12">
                        <v-select
                            v-model="selected_user"
                            :items="user_items"
                            item-text="user_text"
                            item-value="user_value"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                            <span class="user-add-section-title">Organization</span>
                        </v-col>
                        <v-col cols="12">
                        <v-select
                            v-model="selected_org"
                            :items="org_items"
                            item-text="org_text"
                            item-value="org_value"
                        >
                        </v-select>
                    </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0" justify="space-around">
                        <v-col cols="auto" v-if="!confirm">
                            <v-btn class="user-add-input" @click="add_user_to_org">
                                Add
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-tab-item> -->
        <!-- </v-tabs-items> -->
    </v-card>
</template>

<script>
import docma_logo from "@/assets/docma-logo-dark.svg";
import vue_logo from "@/assets/logo.svg";
import api_caller from "@/javascript/user_org_management.js";
export default {
    name: 'AddUserCard',

    data: () => ({
        selected_user: "",
        selected_org: "",
        user_items: [],
        org_items: [],
        action_tab: null,
        first_name: "",
        last_name: "",
        title: "",
        organization: "Docma",
        logo: docma_logo,
        email: '',
        password: '',
        organization_options: [
            'Docma',
            'Vue',
            'RIT',
            'Pattison',
            'Broadsign'
        ],
        confirm: false,
        rules: {
          name: [val => (val || '').length > 0 || 'This field is required'],
          email: [value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          }]
        }
    }),
    props: [
      'user_list',
      'org_list',
    ],
    mounted() {
        if(this.user_list) {
            for (var user_id in this.user_list) {
                this.user_items.push({
                    user_value: user_id,
                    user_text: this.user_list[user_id]["name"],
                });
            }
        }

        if(this.org_list) {
            for (var org_id in this.org_list) {
                this.org_items.push({
                    org_value: org_id,
                    org_text: this.org_list[org_id]["display_name"],
                });
            }
        }
    },
    computed: {
        form_filled () {
            if((this.first_name || '').length <= 0 ){
                return false
            }
            if((this.last_name || '').length <= 0 ){
                return false
            }
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if(!pattern.test(this.email)){
                return false
            }
            if((this.password || '').length <= 10 ){
                return false
            }
            return true
        },
    },
    methods: {
        update_organization_logo: function (organization){
            switch(organization){
                case 'Docma':
                    this.logo = docma_logo;
                    break;
                case 'RIT':
                    this.logo = vue_logo;
                    break;
                default:
                    this.logo = docma_logo;
                    break;
            }
        },
        create_random_password: function(){
            let pass = Math.random().toString(36).slice(2) + Math.random().toString(36).toUpperCase().slice(2);
            console.log(pass);
            this.password = pass;
        },
        copy_text_to_clipboard: async function(){
            let text = this.password;
            try {
                await navigator.clipboard.writeText(text);
                alert('Content copied to clipboard');
            } catch (err) {
                alert('Failed to copy: ', err);
            }
        },
        clear_content: function(){
            this.first_name = '';
            this.last_name = '';
            this.title = '';
            this.organization = 'Docma';
            this.email = '';
            this.password = '';
            this.update_organization_logo(this.organization);
        },
        add_user_to_org: async function() {
            let org_id = this.selected_org
            let user_id = this.selected_user
            const token = await this.$auth.getTokenSilently();
             await api_caller
                .add_org_members(token, org_id, user_id)
                .then((resp) => {
                return resp["data"];
                })
                .catch((e) => {
                console.log(e);
                return "False";
                });
            this.close()
        },
        confirm_user_detail: function(){
            this.confirm=true;
        },
        update_user_detail: function(){
            this.confirm=false;
        },
        add_user: async function(){
            let user_info = {
                'first_name': this.first_name,
                'last_name': this.last_name,
                'email': this.email,
                'password': this.password
            }

            const token = await this.$auth.getTokenSilently();
            let createUserResp = await api_caller
                .create_new_user(token, user_info)
                .then((resp) => {
                return resp["data"];
                })
                .catch((e) => {
                console.log(e);
                return "False";
                });
            console.log(createUserResp);
            this.close()
        },
        close: function(){
            this.clear_content();
            this.$emit('close_add_user');
        }
    }
}
</script>
<style>
    .user-add-card-title{
        font-family: 'Poppins' !important;
        font-weight: 800 !important;
        font-size: 1.2em !important;
    }
    .user-add-section-title{
        font-family: 'Poppins' !important;
        font-weight: 600 !important;
        font-size: 1.2em !important;
    }
    .user-add-input{
        font-family: 'Poppins' !important;
        font-weight: 400 !important;
        font-size: 0.9em !important;
    }
</style>
  