<template>
    <v-card>
        <v-card-title class="user_card_name">
            {{first_name}} {{last_name}}
        </v-card-title>
        <v-card-subtitle class="user_card_title">
            {{title}}
        </v-card-subtitle>
        <v-card-text>
            <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0 mb-3">
                    <span class="user_card_info">{{email}}</span>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                    <span class="user_card_info">Last Logged In</span>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 ml-3">
                    <span class="user_card_info">{{last_login}}</span>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                    <span class="user_card_info">Account Created</span>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0 ml-3">
                    <span class="user_card_info">{{account_created}}</span>
                </v-col>
            </v-row>
        </v-card-text>
        <v-row class="ma-0 pa-0">
            <v-spacer></v-spacer>
            <v-col cols="6">
                <v-img :src=logo contain max-height="30px" max-width="150px"></v-img>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import docma_logo from "@/assets/docma-logo-dark.svg";
export default {
    name: 'UserCard',
    props: [
        'user_info'
    ],
    mounted(){
        if(this.user_info === null){
            this.first_name = '';
            this.last_name = '';
            this.title = '';
        }
        else{
            this.first_name = this.user_info.first_name;
            this.last_name = this.user_info.last_name;
            this.title = this.user_info.title;
            this.email = this.user_info.email;
            this.account_created = new Date(this.user_info.account_created_at).toLocaleDateString();
            this.last_login = new Date(this.user_info.last_login).toLocaleDateString();
        }
    },
    data: () => ({
        first_name: "",
        last_name: "",
        title: "",
        email: "",
        organization: "",
        account_created: "",
        last_login: "",
        logo: docma_logo
    }),
}
</script>

<style scoped>
    .user_card_name{
        font-family: Poppins;
        font-weight: 600;
    }
    .user_card_title{
        font-family: Poppins;
        font-weight: 200;
    }
    .user_card_info{
        font-family: Poppins;
        font-weight: 400;
    }

</style>
  