<template>
  <v-container fluid class="ma-0 pa-12">
    <v-overlay :value="add_user">
      <AddUserCard
        v-on:close_add_user="close_add_user"
        :user_list="all_users_list"
        :org_list="all_orgs_list"
      >
      </AddUserCard>
    </v-overlay>
    <v-row>
      <v-col sm="12" md="4">
        <v-card>
          <v-row class="ma-0 pa-0">
            <v-col cols="12">
              <v-text-field
                hint="Search Name"
                prepend-icon="mdi-magnify"
                icon-color="black"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="selected_org"
                :items="org_list_items"
                item-text="org_text"
                item-value="org_value"
                prepend-icon="mdi-filter"
                icon-color="black"
                @change="on_org_change($event)"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="sort_by"
                :items="sortable_items"
                chips
                clearable
                label="Sort By"
                multiple
                prepend-icon="mdi-sort"
                solo
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="remove(item)"
                  >
                    <strong>{{ item }}</strong
                    >&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="4"> </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="12" md="8" style="max-height: 100%">
        <v-card>
          <v-row class="ma-0 pa-0" style="max-height: 70vh; overflow-y: scroll;">
            <v-col
              sm="6"
              lg="4"
              cols="auto"
              v-for="(user, idx) in filtered_users"
              :key="idx"
            >
              <UserCard :user_info="user"></UserCard>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn fab dark @click="open_add_user">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserCard from "./UserCard.vue";
import AddUserCard from "./AddUser.vue";
import api_caller from "@/javascript/user_org_management.js";

export default {
  name: "UserManage",

  components: {
    UserCard,
    AddUserCard,
  },

  data: () => ({
    all_users_list: [],
    all_orgs_list: [],
    filtered_users: [],
    org_list_items: [{ org_value: "all", org_text: "All" }],
    selected_org: { org_value: "all", org_text: "All" },
    add_user: false,
    sort_by: [],
    sortable_items: ["Organization", "First Name", "Last Name", "Last Logged In"],
  }),
  mounted() {
    this.get_all_users();
    this.get_orgs();
  },
  methods: {
    open_add_user: function () {
      this.add_user = true;
    },
    close_add_user: function () {
      this.add_user = false;
    },
    get_all_users: async function () {
      const token = await this.$auth.getTokenSilently();
      let userDataset = await api_caller
        .get_all_users(token)
        .then((resp) => {
          return resp["data"];
        })
        .catch((e) => {
          console.log(e);
          return "False";
        });
      this.all_users_list = userDataset;
      this.filtered_users = this.all_users_list
    },
    get_orgs: async function () {
      const token = await this.$auth.getTokenSilently();
      let orgDataset = await api_caller
        .get_all_orgs(token)
        .then((resp) => {
          return resp["data"];
        })
        .catch((e) => {
          console.log(e);
          return "False";
        });
      
      this.all_orgs_list = orgDataset

      for (var org_key in orgDataset) {
        this.org_list_items.push({
          org_value: org_key,
          org_text: orgDataset[org_key]["display_name"],
        });
        this.get_org_members(org_key)
      }
    },
    get_org_members: async function (org_id) {
      const token = await this.$auth.getTokenSilently();
      let orgMemberDataset = await api_caller
        .get_org_members(token, org_id)
        .then((resp) => {
          return resp["data"];
        })
        .catch((e) => {
          console.log(e);
          return "False";
        });
      for (var userId in orgMemberDataset) {
        if(this.all_users_list[userId]) {
          if(this.all_users_list[userId]["organization"]) {
            this.all_users_list[userId]["organization"].push(org_id)
          } else {
            this.all_users_list[userId]["organization"] = [org_id]
          }
        }
      }
    },

    on_org_change: async function(org_id) {
      if(org_id == 'all') {
        this.filtered_users = this.all_users_list
      } else {
        let tempUserSet = {}
        for (var userId in this.all_users_list) {
          let userDetail = this.all_users_list[userId]
          if(userDetail["organization"] && userDetail["organization"].includes(org_id)) {
            tempUserSet[userId] = userDetail
          }
        }
        this.filtered_users = tempUserSet 
      }
    },
  },
};
</script>
