<template>
  <v-container fluid class="ma-0 pa-12">
    <v-overlay :value="add_user">
      <AddUserCard
        v-on:close_add_user="close_add_user"
        :user_list="all_users_list"
        :org_list="all_orgs_list"
      >
      </AddUserCard>
    </v-overlay>
    <v-row>
      <v-col cols="12">
        <v-card style="height: 80vh; overflow-y: scroll;">
          <v-row class="ma-0 pa-0" style="background-color: black; height: 88px;">
            <v-col cols="auto">
              <v-card-title class="directory_title white--text text-h5">
                User Directory
              </v-card-title>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" v-if="this.users.length > 0">
              <v-btn fab color="white" @click="open_add_user" :disabled="data_loading">
                <v-icon large>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn fab color="white" @click="reload_data" :disabled="data_loading">
                <v-icon large>mdi-refresh</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0" justify="space-between" v-if="!data_loading" style="max-height: calc(75vh - 112px); overflow-y: hidden;">
            <v-col cols="5" style="max-height: calc(75vh - 160px); overflow-y: scroll;">
              <v-treeview
                :active.sync="active"
                :items="items"
                activatable
                color="warning"
                open-on-click
                transition
                :multiple-active="false"
                :disabled="user_edits_made"
              >
                <template v-slot:prepend="{ item }">
                  <v-icon v-if="!item.children">
                    mdi-account
                  </v-icon>
                </template>
              </v-treeview>
            </v-col>

            <v-divider vertical></v-divider>

            <v-col
              class="text-center"
            >
              <v-scroll-y-transition mode="out-in">
                <div
                  v-if="!selected"
                  class="text-h6 grey--text text--lighten-1 font-weight-light"
                  style="align-self: center;"
                >
                  Select a User
                </div>
                <v-row class="ma-0 pa-0" v-else>
                  <v-row class="ma-0 pa-0" style="height: 20%">
                    <v-col cols="auto" class="ma-0 pa-0" align="left">
                      <v-chip label class="data_breakdown_chip" :outlined="user_details_type != 'details'" @click="user_details_type = 'details'" color="black">
                        Details
                      </v-chip>
                      <v-chip label class="data_breakdown_chip" :outlined="user_details_type != 'organizations'" @click="user_details_type = 'organizations'"  color="black">
                        Organizations
                      </v-chip>
                      <v-chip label class="data_breakdown_chip" :outlined="user_details_type != 'access'" @click="user_details_type = 'access'"  color="black">
                        Project Access
                      </v-chip>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" justify="middle">
                      <v-btn icon @click="edit_mode = !edit_mode" :disabled="user_edits_made">
                        <v-icon v-if="!edit_mode" color="black">mdi-pencil-outline</v-icon>
                        <v-icon v-if="edit_mode" color="black">mdi-pencil-off-outline</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto" justify="middle">
                      <v-btn icon @click="undo_user_edits" :disabled="(!edit_mode || !user_edits_made)">
                        <v-icon color="black">mdi-undo-variant</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto" justify="middle">
                      <v-btn icon @click="save_user_edits" :disabled="(!edit_mode || !user_edits_made)">
                        <v-icon color="black">mdi-content-save-settings-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row style="width: 100%">
                    <!-- User Details -->
                    <v-col cols="12" v-if="user_details_type == 'details'">
                      <v-card
                        :key="selected.id"
                        class="pt-6 mx-auto"
                        flat
                        max-width="400"
                      >
                        <v-row>
                          <v-col cols="12">
                            <v-avatar
                              size="88"
                            >
                              <v-img
                                :src="this.user_avatar()"
                                class="mb-6"
                              ></v-img>
                            </v-avatar>
                          </v-col>
                          <v-col cols="12">
                            <h3 class="text-h5 mb-2">
                              {{ selected.name }}
                            </h3>
                          </v-col>
                          <v-col cols="12">
                            <div class="blue--text mb-2">
                              {{ selected.email }}
                            </div>
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row
                          class="text-left"
                        >
                          <v-col
                            class="text-right"
                            tag="strong"
                            cols="5"
                          >
                            Account Created:
                          </v-col>
                          <v-col cols="7">{{ selected.account_created_at }}</v-col>
                          <v-col
                            class="text-right"
                            tag="strong"
                            cols="5"
                          >
                            Last Login:
                          </v-col>
                          <v-col cols="7">{{ selected.last_login }}</v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <!-- Organization Selection -->
                    <v-col cols="12" v-if="user_details_type == 'organizations'">
                      <v-card
                        :key="selected.id"
                        class="pt-6 mx-auto"
                        flat
                        max-width="400"
                      >
                        <v-row
                          class="text-left"
                        >
                          <v-col cols="12">
                            <v-checkbox v-for="org in org_list_items" :label="org.org_text" :key="org.org_value" 
                                        v-model="selected['organizations']" :value="org.org_text" :disabled="!edit_mode"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <!-- Project Access Selection -->
                    <v-col cols="12" v-if="user_details_type == 'access'">
                      <v-treeview
                        v-model="selected['access']"
                        :items="available_projects"
                        :selection-type="selectionType"
                        selectable
                        :disabled="!edit_mode"
                        :multiple-active="false"
                      ></v-treeview>
                    </v-col>
                  </v-row>
                
                </v-row>
          
              </v-scroll-y-transition>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" align="center">
              <v-progress-circular indeterminate color="black" size="100"></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import UserCard from "./UserCard.vue";
import AddUserCard from "./AddUser.vue";
import api_caller from "@/javascript/user_org_management.js";

export default {
  name: "UserManage",

  components: {
    // UserCard,
    AddUserCard,
  },

  data: () => ({
    data_loading: true,

    user_details_type: 'access',
    all_users_list: {},
    all_orgs_list: [],
    filtered_users: [],
    org_list_items: [],
    selected_org: {},
    add_user: false,
    sort_by: [],
    sortable_items: ["Organization", "First Name", "Last Name", "Last Logged In"],
    active: [],
    avatar: null,
    open: [],
    users: [],

    edit_mode: false,

    selected_user: null,

    available_projects: [],
    base_projects: [],
    base_orgs: [],
    selectionType: 'leaf'
    
  }),
  mounted() {
    this.reload_data();
  },
  computed: {
      items () {
        let ulst = []
        let key;
        let val;
        for([key,val] of Object.entries(this.all_users_list)){
          ulst.push({
            'name': key,
            'id': key,
            'children': [...val]
          })
        }
        console.log(ulst);
        return ulst;
      },
      selected () {
        return this.pull_user();
      },
      user_edits_made(){
        if (!this.selected) return false;

        if(!(JSON.stringify(this.base_projects) === JSON.stringify(this.selected['access']))){
          return true;
        }
        if(!(JSON.stringify(this.base_orgs) === JSON.stringify(this.selected['organizations']))){
          return true;
        }
        return false;
      }
    },
  methods: {
    user_avatar (){
      let avatars = [
        'https://avataaars.io/?avatarStyle=Circle&topType=ShortHairDreads02&accessoriesType=Wayfarers&hairColor=Red&facialHairType=BeardLight&facialHairColor=Red&clotheType=ShirtScoopNeck&clotheColor=Blue02&eyeType=Wink&eyebrowType=UnibrowNatural&mouthType=Tongue&skinColor=Tanned',
        'https://avataaars.io/?accessoriesType=Sunglasses&avatarStyle=Circle&clotheColor=Gray02&clotheType=ShirtScoopNeck&eyeType=EyeRoll&eyebrowType=RaisedExcited&facialHairColor=Red&facialHairType=BeardMagestic&hairColor=Red&hatColor=White&mouthType=Twinkle&skinColor=DarkBrown&topType=LongHairBun',
        'https://avataaars.io/?accessoriesType=Prescription02&avatarStyle=Circle&clotheColor=Black&clotheType=ShirtVNeck&eyeType=Surprised&eyebrowType=Angry&facialHairColor=Blonde&facialHairType=Blank&hairColor=Blonde&hatColor=PastelOrange&mouthType=Smile&skinColor=Black&topType=LongHairNotTooLong',
        'https://avataaars.io/?accessoriesType=Round&avatarStyle=Circle&clotheColor=PastelOrange&clotheType=Overall&eyeType=Close&eyebrowType=AngryNatural&facialHairColor=Blonde&facialHairType=Blank&graphicType=Pizza&hairColor=Black&hatColor=PastelBlue&mouthType=Serious&skinColor=Light&topType=LongHairBigHair',
        'https://avataaars.io/?accessoriesType=Kurt&avatarStyle=Circle&clotheColor=Gray01&clotheType=BlazerShirt&eyeType=Surprised&eyebrowType=Default&facialHairColor=Red&facialHairType=Blank&graphicType=Selena&hairColor=Red&hatColor=Blue02&mouthType=Twinkle&skinColor=Pale&topType=LongHairCurly'
      ]
      let idx = Math.floor(Math.random() * 5);
      console.log(idx)
      return avatars[idx];
    },
    pull_user: function(){
      if (!this.active.length) {
          this.selected_user = null;
          this.base_projects = [];
          this.base_orgs = [];
          this.edit_mode = false;
          return undefined
        }
        console.log(this.active)
        let id = this.active[0];
        id = id.split('__');
        if (id.length == 1){
          id = id[0];
        }
        else{
          id = id[1];
        }
        
        let user = this.users.find(user => user.user_id === id)

        if(id != this.selected_user){
          this.edit_mode = false;
          this.selected_user = id;
          this.base_projects = [...user['access']];
          this.base_orgs = [...user['organizations']];
        }

        return user
    },
    open_add_user: function () {
      this.add_user = true;
    },
    close_add_user: function () {
      this.add_user = false;
    },
    reload_data: async function(){
      this.data_loading = true;
      this.selected_user = null;
      await this.get_all_users();
      await this.get_orgs();
      await this.get_all_projects();
      this.data_loading = false;
    },
    get_all_users: async function () {
      const token = await this.$auth.getTokenSilently();
      const env = this.$environment.get_val();
      this.all_users_list['All Users'] = []
      this.users = await api_caller
        .get_all_users(token, env)
        .then((resp) => {
          let users = [];
          for(let u in resp["data"]){
            let us = resp['data'][u];
            us['id'] = us['user_id'];
            us['organizations'] = [];
            users.push(us);
          }
          return users
        })
        .catch((e) => {
          console.log(e);
          return [];
        });
        this.all_users_list['All Users'] = [...this.users];
    },
    get_all_projects: async function () {
      const token = await this.$auth.getTokenSilently();
      const env = this.$environment.get_val();
      this.available_projects =  await api_caller
        .get_all_projects(token, env)
        .then((resp) => {
          return resp['data']})
        .catch((e) => {
          console.log(e);
          return [];
        });
    },
    get_orgs: async function () {
      const token = await this.$auth.getTokenSilently();
      const env = this.$environment.get_val();
      let orgDataset = await api_caller
        .get_all_orgs(token,env)
        .then((resp) => {
          console.log(resp)
          return resp["data"];
        })
        .catch((e) => {
          console.log(e);
          return [];
        });
      
      this.all_orgs_list = orgDataset;
      this.org_list_items = [];

      for (var org_key in orgDataset) {
        this.org_list_items.push({
          org_value: org_key,
          org_text: orgDataset[org_key]["display_name"],
        });
        await this.get_org_members(org_key, orgDataset[org_key]["display_name"])
      }

    },
    get_org_members: async function (org_id, org_name) {
      const token = await this.$auth.getTokenSilently();
      const env = this.$environment.get_val();
      let orgMemberDataset = await api_caller
        .get_org_members(token, org_id, env)
        .then((resp) => {
          return resp["data"];
        })
        .catch((e) => {
          console.log(e);
          return "False";
        });
      this.all_users_list[org_name] = []
      for (let i = 0; i < this.users.length; i++){
        let u  = {...this.users[i]};
        if(Object.keys(orgMemberDataset).includes(u['id'])){
          if(u["organizations"]) {
            this.users[i]["organizations"].push(org_name)
          } else {
            this.users[i]["organizations"] = [org_name]
          }
          u['id'] = org_name + "__" + u['user_id'];
          this.all_users_list[org_name].push({...u});
        }
      }
    },
    undo_user_edits: function(){
      if(this.user_edits_made){
        this.selected.access = this.base_projects;
        this.selected.organizations = this.base_orgs;
      }
    },
    save_user_edits: async function(){
      if(this.user_edits_made){
        if(!(JSON.stringify(this.base_projects) === JSON.stringify(this.selected['access']))){
          let added = this.selected.access.filter(x => !this.base_projects.includes(x));
          let removed = this.base_projects.filter(x => !this.selected.access.includes(x));
          const token = await this.$auth.getTokenSilently();
          const env = this.$environment.get_val();
          api_caller.assign_access(token, this.selected.user_id, env, added, removed);
        }
        if(!(JSON.stringify(this.base_orgs) === JSON.stringify(this.selected['organizations']))){
          console.log(this.selected.organizations)
        }
        this.reload_data();
      }
    },
  },
};
</script>

<style scoped>
  .directory_title{
    font-family: 'Poppins' !important;
    font-weight: 600 !important;
    font-size: 1.2em;
  }
  .data_breakdown_chip {
    font-family: Poppins;
    color: white !important;
    font-weight: 600;
    width: 125px;
    justify-content: center;
    margin-left: 1px;
    margin-right: 1px;
  }
  .data_breakdown_chip.v-chip.v-chip--outlined.v-chip.v-chip{
    color: black!important;
  }
</style>
